import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { Form, Dropdown } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import { FormControl, Select, MenuItem } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent,Typography    } from '@mui/material';






const BarChartComponent = () => {
  const [chartData, setChartData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('January-2024');
  const [selectedProject, setSelectedProject] = useState('All Projects');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://billingreports.bsite.net/api/Reporting/Maitenance');
      setChartData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
  };

  const filteredData = chartData.filter(
    (data) =>
      data.Month.includes(selectedMonth) && (!selectedProject || data.ProjectName === selectedProject)
  );

  const columns = [
    { field: 'Month', headerName: 'Month', flex: 1 },
    { field: 'ProjectName', headerName: 'Project Name', flex: 1 },
    { field: 'TotalBills', headerName: 'Bills Generated', flex: 1 },
    // { field: 'TotalBills', headerName: 'Generated Bills', flex: 1 },
    { field: 'MembersPaid', headerName: 'Bills Paid', flex: 1 },
    { field: 'MembersUnpaid', headerName: 'Bils Unpaid', flex: 1 },
  ];

  const rows = filteredData.map((dataItem, index) => ({
    id: index,
    Month: dataItem.Month,
    ProjectName: dataItem.ProjectName,
    TotalBills: dataItem.TotalBills,
    TotalMembers: dataItem.TotalMembers,
    MembersPaid: dataItem.MembersPaid.toLocaleString(),
    MembersUnpaid: dataItem.MembersUnpaid.toLocaleString(),
  }));


  return (

    <div style={{ marginTop: '0px' }}>

<div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <h3 style={{ font: 'verdana', color: 'blue', fontSize: '30px', marginRight: '10px',marginLeft:'100px' }}>Electricity Bills Paid /Unpaid </h3> */}
        <Card style={{ Width: 400, margin: '0 auto', marginTop: 20 ,marginLeft:'80px' ,backgroundColor:'#0E2954' }}>
          <CardContent>
            <Typography variant="h6" component="h6" style={{ color: 'white' }}>
             Bills Paid /Unpaid
            </Typography>
          </CardContent>
        </Card>
        <FormControl style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '10px' }}>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          <MenuItem value="January-2024">January</MenuItem>
          <MenuItem value="February">February</MenuItem>
          <MenuItem value="March">March</MenuItem>
          <MenuItem value="April">April</MenuItem>
          <MenuItem value="May">May</MenuItem>
          <MenuItem value="June">June</MenuItem>
          <MenuItem value="July">July</MenuItem>
          <MenuItem value="August">August</MenuItem>
          <MenuItem value="September">September</MenuItem>
          <MenuItem value="October">October</MenuItem>
          <MenuItem value="November">November</MenuItem>
          <MenuItem value="December">December</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: '10px' }}>
        <Select
          value={selectedProject}
          onChange={handleProjectChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          <MenuItem value="All Projects">All Projects</MenuItem>
          {/* <MenuItem value="Mohlanwal">Mohlanwal</MenuItem>
          <MenuItem value="Orchards">Orchards</MenuItem> */}
        </Select>
      </FormControl>
      </div>

      {/* <FormControl style={{marginLeft:'50px'}}>
  <Select value={selectedBank} onChange={handleBankChange}>
    <MenuItem value="">All Banks</MenuItem>
    {uniqueBanks.map((bank) => (
      <MenuItem key={bank} value={bank}>
        {bank}
      </MenuItem>
    ))}
  </Select>
</FormControl> */}


      {/* Bar Chart */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} justifyContent="center" style={{ marginLeft: '20px' }}>
          <div style={{ height: 400, marginLeft: 60, marginTop: 20, border: '1px solid black' }}>
            <h6 style={{ marginBottom: 20, textAlign: 'center' }}>Bills Paid/Unpaid</h6>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={filteredData} barSize={60}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Month" />
                <YAxis domain={[0, 30000]} />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="ProjectName" name="Project Name" fill="#ffa600" label="insideRight" ><LabelList dataKey="ProjectName" position="top" formatter={(value) => value.toLocaleString()} /></Bar> */}
                {/* <Bar dataKey="TotalMembers" name="Total Members" fill="#ab47bc" label="insideRight" ><LabelList dataKey="TotalMembers" position="top" formatter={(value) => value.toLocaleString()} /></Bar> */}
                <Bar dataKey="TotalBills" name="Generated Bills" fill="#0e103d" label="insideRight" ><LabelList dataKey="TotalBills" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
                <Bar dataKey="MembersPaid" name="Bills Paid" fill="#5e548e" label="insideRight" ><LabelList dataKey="MembersPaid" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
                <Bar dataKey="MembersUnpaid" name="Bills Unpaid" fill="red" label="insideRight" ><LabelList dataKey="MembersUnpaid" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>

        <Grid item xs={13} md={16} justifyContent="center" style={{ marginLeft: '90px' }}>
          <div >
            <Table style={{ border: '1px solid black' }} >
              <thead>
                <tr>
                  <th style={{ color: 'black', border: '1px solid black' }} align="center">Month</th>
                  <th style={{ color: 'black' }}>Total Members</th>
                  <th style={{ color: 'black', border: '1px solid black' }} align="center">Project Name</th>
                  <th style={{ color: 'black', border: '1px solid black' }} align="center">Total Bills Generated</th>
                  <th style={{ color: 'black', border: '1px solid black' }} align="center">Bills Paid</th>
                  <th style={{ color: 'black', border: '1px solid black' }} align="center">Bills UnPaid</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.id}>
                    <td style={{ color: 'black', border: '1px solid black' }} align="center">{row.Month}</td>
                    <td style={{ color: 'black', border: '1px solid black' }} align="center">{row.TotalMembers}</td>
                    <td style={{ color: 'black', border: '1px solid black' }} align="center">{row.ProjectName}</td>
                    <td style={{ color: 'black', border: '1px solid black' }} align="center">{row.TotalBills}</td>
                    <td style={{ color: 'black', border: '1px solid black' }} align="center">{row.MembersPaid}</td>
                    <td style={{ color: 'black', border: '1px solid black' }} align="center">{row.MembersUnpaid}</td>
                    {/* <td style={{ color: 'black' ,border:'1px solid black'}}  align="center">{row.BillAmountUnpaid}</td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Grid>
      </Grid>


    </div>
  );
};

export default BarChartComponent;