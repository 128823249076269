import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { Form, Dropdown } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import { FormControl, Select, MenuItem } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';






const BillsPaidUnPaid = ({ selectedProject }) => {
    const [chartData, setChartData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://billingreports.bsite.net/api/Reporting/Electricity');
            setChartData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const filteredData = chartData.filter(
        (data) =>
            data.Month.includes(selectedMonth) && (!selectedProject || data.ProjectName === selectedProject)
    );

    const columns = [
        { field: 'Month', headerName: 'Month', flex: 1 },
        { field: 'ProjectName', headerName: 'Project Name', flex: 1 },
        { field: 'TotalBills', headerName: 'Bills Generated', flex: 1 },
        // { field: 'TotalBills', headerName: 'Generated Bills', flex: 1 },
        { field: 'MembersPaid', headerName: 'Bills Paid', flex: 1 },
        { field: 'MembersUnpaid', headerName: 'Bils Unpaid', flex: 1 },
    ];

    const rows = filteredData.map((dataItem, index) => ({
        id: index,
        Month: dataItem.Month,
        ProjectName: dataItem.ProjectName,
        TotalBills: dataItem.TotalBills,
        TotalMembers: dataItem.TotalMembers,
        MembersPaid: dataItem.MembersPaid.toLocaleString(),
        MembersUnpaid: dataItem.MembersUnpaid.toLocaleString(),
    }));


    return (

        <div style={{ marginTop: '50px' }}>

            {/* Bar Chart */}
            <Grid container spacing={2}>
            <Grid item xs={12} md={12} justifyContent="center" >
                <div style={{ height: 400, marginLeft: 10, marginTop: 20, border: '1px solid black' }}>
                        <h6 style={{ marginBottom: 20, textAlign: 'center' }}>Electricity Bills Paid/Unpaid</h6>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={filteredData} barSize={40}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Month" />
                                <YAxis domain={[0, 30000]} />
                                <Tooltip />
                                <Legend />
                                {/* <Bar dataKey="ProjectName" name="Project Name" fill="#ffa600" label="insideRight" ><LabelList dataKey="ProjectName" position="top" formatter={(value) => value.toLocaleString()} /></Bar> */}
                                {/* <Bar dataKey="TotalMembers" name="Total Members" fill="#ab47bc" label="insideRight" ><LabelList dataKey="TotalMembers" position="top" formatter={(value) => value.toLocaleString()} /></Bar> */}
                                <Bar dataKey="TotalBills" name="Generated Bills" fill="#0e103d" label="insideRight" ><LabelList dataKey="TotalBills" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
                                <Bar dataKey="MembersPaid" name="Bills Paid" fill="#5e548e" label="insideRight" ><LabelList dataKey="MembersPaid" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
                                <Bar dataKey="MembersUnpaid" name="Bills Unpaid" fill="red" label="insideRight" ><LabelList dataKey="MembersUnpaid" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Grid>

                
            </Grid>
        </div>
    );
};

export default BillsPaidUnPaid;