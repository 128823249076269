

import React, { useState, useContext } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { pink } from "@mui/material/colors";
import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  Button,
  useMediaQuery,
  IconButton,
  useTheme,
  Menu,
  MenuItem,
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import CottageIcon from "@mui/icons-material/Cottage";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import HomeIcon from "@mui/icons-material/Home";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";



const HeaderMUI = () => {
  const [value, setValue] = useState();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const handlePhoneIconClick = () => {
    window.open("tel:03000234880");
  };

  const [anchorElElectricity, setAnchorElElectricity] = useState(null);
  const [anchorElMaintenance, setAnchorElMaintenance] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [anchorElNetMetering, setAnchorElNetmetring] = useState(null);
  
  const handleDropdownClick = (event, dropdownName) => {
    if (dropdownName === "Electricity") {
      setAnchorElElectricity(event.currentTarget);
    } else if (dropdownName === "Maintenance") {
      setAnchorElMaintenance(event.currentTarget);
    }
    else if (dropdownName === "Settings") {
      setAnchorElSettings(event.currentTarget);
    }
    else if (dropdownName === "NetMetering") {
      setAnchorElNetmetring(event.currentTarget);
    }
  };





  const handleDropdownClose = (dropdownName) => {
    if (dropdownName === "Electricity") {
      setAnchorElElectricity(null);
    } else if (dropdownName === "Maintenance") {
      setAnchorElMaintenance(null);
    }
    else if (dropdownName === "Settings") {
      setAnchorElSettings(null);
    }

    else if (dropdownName === "NetMetering") {
      setAnchorElNetmetring(null);
    }

  };
 
  const handleLogout = () => {
    // Clear user data from Redux store
    
    //alert("ok")
    // Clear user data from local storage
    // localStorage.removeItem('userData');
    // dispatch(setUserData({ email: '', usertype: '' }));
    Cookies.remove("jwtToken");
    Cookies.remove("userrights")
    navigate('/');
    window.location.reload() 
    
  };

  return (
    <React.Fragment>
      <AppBar position="absolute" sx={{ background: "#063970" }}>
        <Toolbar>
          <Button
            variant="link"
            color="default"
            startIcon={<HomeIcon sx={{ color: pink[700] }} />}
            href="/"
          >
            Home
          </Button>

          <Tabs
            sx={{ margin: "auto" }}
            textColor="inherit"
            value={value}
            onChange={(e, value) => setValue(value)}
            indicatorColor="secondary"
          >
            <Tab
              label="Electricity"
              aria-controls="menu-electricity"
              aria-haspopup="true"
              onClick={(e) => handleDropdownClick(e, "Electricity")}
            />



            <Tab
              label="Maintenance"
              aria-controls="menu-maintenance"
              aria-haspopup="true"
              onClick={(e) => handleDropdownClick(e, "Maintenance")}
            />


            {/* <Tab
              label="Net Metering"
              aria-controls="menu-netmetering"
              aria-haspopup="true"
              onClick={(e) => handleDropdownClick(e, "NetMetering")}
            /> */}


            {/* <Tab
              label="Settings"
              aria-controls="menu-Settings"
              aria-haspopup="true"
              onClick={(e) => handleDropdownClick(e, "Settings")}
            /> */}
          </Tabs>



          <Menu
            id="menu-electricity"
            anchorEl={anchorElElectricity}
            open={Boolean(anchorElElectricity)}
            onClose={() => handleDropdownClose("Electricity")}
          >
            <MenuItem
              component={Link}
              to="/electricdashboard"
              onClick={() => handleDropdownClose("Electricity")}
            >
              Electricity Dashboard
            </MenuItem>
            <MenuItem
              component={Link}
              to="/bankreport"
              onClick={() => handleDropdownClose("Electricity")}
            >
              Amount Bank wise
            </MenuItem>
            <MenuItem
              component={Link}
              to="/amountpaidunpaid"
              onClick={() => handleDropdownClose("Electricity")}
            >
              Amount Paid UnPaid
            </MenuItem>


            <MenuItem
              component={Link}
              to="/mempaidunpaid"
              onClick={() => handleDropdownClose("Electricity")}
            >
              Mem PaidUnPaid
            </MenuItem>




            <MenuItem
              component={Link}
              to="/resicomm"
              onClick={() => handleDropdownClose("Electricity")}
            >
              Mohlanwal/ Orchards
            </MenuItem>
          </Menu>










          <Menu
            id="menu-maintenance"
            anchorEl={anchorElMaintenance}
            open={Boolean(anchorElMaintenance)}
            onClose={() => handleDropdownClose("Maintenance")}
          >
            <MenuItem
              component={Link}
              to="/maintdashboard"
              onClick={() => handleDropdownClose("Maintenance")}
            >
              Maintenance Dashboard
            </MenuItem>



            <MenuItem
              component={Link}
              to="/maintamountpaidunpaid"
              onClick={() => handleDropdownClose("Maintenance")}
            >
              Amount paid/Unpaid
            </MenuItem>



            <MenuItem
              component={Link}
              to="/maintpaidunpaid"
              onClick={() => handleDropdownClose("Maintenance")}
            >
              Members Paid/UnPaid
            </MenuItem>



            <MenuItem
              component={Link}
              to="/maintmembill"
              onClick={() => handleDropdownClose("Maintenance")}
            >
              Members / Bills
            </MenuItem>

          </Menu>









          <Menu
            id="menu-netmetering"
            anchorEl={anchorElNetMetering}
            open={Boolean(anchorElNetMetering)}
            onClose={() => handleDropdownClose("NetMetering")}
          >
            <MenuItem
              component={Link}
              to="/Netdashboard"
              onClick={() => handleDropdownClose("NetMetering")}
            >
              Net Metering Dashboard
            </MenuItem>
            
            
            
            <MenuItem
              component={Link}
              to="/netbankreport"
              onClick={() => handleDropdownClose("NetMetering")}
            >
              Amount Bank wise
            </MenuItem>
           
           
           
            <MenuItem
              component={Link}
              to="/netpaidunpaid"
              onClick={() => handleDropdownClose("NetMetering")}
            >
              Amount Paid UnPaid
            </MenuItem>


            <MenuItem
              component={Link}
              to="/Netmempaidunpaid"
              onClick={() => handleDropdownClose("NetMetering")}
            >
              Mem PaidUnPaid
            </MenuItem>




            {/* <MenuItem
              component={Link}
              to="/admin/ResiComm"
              onClick={() => handleDropdownClose("NetMetering")}
            >
              Mohlanwal/ Orchards
            </MenuItem> */}
          </Menu>







          <Menu
            id="menu-Settings"
            anchorEl={anchorElSettings}
            open={Boolean(anchorElSettings)}
            onClose={() => handleDropdownClose("Settings")}
          >
            {/* <MenuItem
              component={Link}
              to="/admin/Passupdate"
              onClick={() => handleDropdownClose("Settings")}
            >
              Update Password
            </MenuItem> */}


            {/* 
            <MenuItem
              component={Link}
              to="/registeruser"
              onClick={() => handleDropdownClose("Settings")}
            >
              Register User
            </MenuItem> */}

          </Menu>

          <Button
            variant="link"
            color="inherit"
            startIcon={<LogoutIcon />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>


    </React.Fragment>
  );
};

export default HeaderMUI;


//btkpgck-4576