import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer , LabelList} from 'recharts';
import { Form, Dropdown } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import { FormControl, Select, MenuItem } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent,Typography } from '@mui/material';




const BarChartComponent = () => {
    const [chartData, setChartData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('January-2024');
    const [selectedProject, setSelectedProject] = useState('All Projects');
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await axios.get('https://billingreports.bsite.net/api/Reporting/Electricity');
        setChartData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    const handleMonthChange = (event) => {
      setSelectedMonth(event.target.value);
    };
  
    const handleProjectChange = (event) => {
      setSelectedProject(event.target.value);
    };
  
    const filteredData = chartData.filter(
      (data) =>
        data.Month.includes(selectedMonth) && (!selectedProject || data.ProjectName === selectedProject)
    );
  
    const columns = [
      { field: 'Month', headerName: 'Month', flex: 1 },
      { field: 'ProjectName', headerName: 'Project Name', flex: 1 },
      { field: 'TotalMembers', headerName: 'Total Members', flex: 1 },
      { field: 'MohlanwalMem', headerName: 'Mohlanwal Members', flex: 1 },
      { field: 'OrchardsMem', headerName: 'Orchards Member', flex: 1 },
      
    ];
  
    const rows = filteredData.map((dataItem, index) => ({
      id: index,
      Month: dataItem.Month,
      ProjectName: dataItem.ProjectName,
      TotalMembers: dataItem.TotalMembers,
      MohlanwalMem: dataItem.MohlanwalMem,
      OrchardsMem: dataItem.OrchardsMem,
  
    }));
  

    return (





      <div style={{ marginTop: '0px' }}>

<div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <h3 style={{ font: 'verdana', color: 'blue', fontSize: '30px', marginRight: '10px',marginLeft:'100px' }}>Members Mohlanwal/Orchards </h3> */}
        <Card style={{ Width: 400, margin: '0 auto', marginTop: 20 ,marginLeft:'80px' ,backgroundColor:'#0E2954' }}>
          <CardContent>
            <Typography variant="h6" component="h6" style={{ color: 'white' }}>
            Memebers  Mohlanwal/Orchards
            </Typography>
          </CardContent>
        </Card>
        <FormControl style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '10px' }}>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          <MenuItem value="January-2024">January</MenuItem>
          <MenuItem value="February">February</MenuItem>
          <MenuItem value="March">March</MenuItem>
          <MenuItem value="April">April</MenuItem>
          <MenuItem value="May">May</MenuItem>
          <MenuItem value="June">June</MenuItem>
          <MenuItem value="July">July</MenuItem>
          <MenuItem value="August">August</MenuItem>
          <MenuItem value="September">September</MenuItem>
          <MenuItem value="October">October</MenuItem>
          <MenuItem value="November">November</MenuItem>
          <MenuItem value="December">December</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: '10px' }}>
        <Select
          value={selectedProject}
          onChange={handleProjectChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          <MenuItem value="All Projects">All Projects</MenuItem>
          <MenuItem value="Mohlanwal">Mohlanwal</MenuItem>
          <MenuItem value="Orchards">Orchards</MenuItem>
        </Select>
      </FormControl>
      </div>



        {/* Bar Chart */}
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} justifyContent="center" style={{ marginLeft: '20px' }}>
                <div style={{ height: 400, marginLeft: 60, marginTop: 20, border: '1px solid black' }}>
                    <h6 style={{ marginBottom: 20, textAlign: 'center' }}>Members Res/Comm</h6>
                    <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={filteredData} barSize={80}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Month" />
          <YAxis domain={[0, 30000]} />
          <Tooltip />
          <Legend />
          {/* <Bar dataKey="ProjectName" name="Project Name" fill="#ffa600" label="insideRight" ><LabelList dataKey="ProjectName" position="top" formatter={(value) => value.toLocaleString()} /></Bar> */}
          <Bar dataKey="TotalMembers" name="Total Members" fill="#bc5090" label="insideRight" ><LabelList dataKey="TotalMembers" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
              <Bar dataKey="MohlanwalMem" name="Mohlanwal Members" fill="#ff6361" label="insideRight" ><LabelList dataKey="CommMember" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
              <Bar dataKey="OrchardsMem" name="Orchard Members" fill="#58508d" label="insideRight" ><LabelList dataKey="ResiMembers" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Grid>
           




            <Grid item xs={13} md={16} justifyContent="center" style={{ marginLeft: '90px' }}>
                <div >
                    <Table style={{border:'1px solid black'}} >
                        <thead>
                            <tr>
                                <th style={{ color: 'black' ,border:'1px solid black'}}  align="center">Month</th>
                                <th style={{ color: 'black' }}>Total Members</th>
                                <th style={{ color: 'black',border:'1px solid black' }}  align="center">Project Name</th>
                                <th style={{ color: 'black',border:'1px solid black' }}  align="center">Mohlanwal</th>
                                <th style={{ color: 'black',border:'1px solid black' }}  align="center">Orchards</th>

                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row) => (
                                <tr key={row.id}>
                                    <td  style={{ color: 'black' ,border:'1px solid black'}}  align="center">{row.Month}</td>
                                    <td  style={{ color: 'black' ,border:'1px solid black'}}  align="center">{row.TotalMembers}</td>
                                    <td  style={{ color: 'black' ,border:'1px solid black'}}  align="center">{row.ProjectName}</td>
                                    <td style={{ color: 'black' ,border:'1px solid black'}}  align="center">{row.MohlanwalMem}</td>
                                    <td style={{ color: 'black' ,border:'1px solid black'}}  align="center">{row.OrchardsMem}</td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Grid>
        </Grid>


        </div>
    );
};

export default BarChartComponent;