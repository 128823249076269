// import React, { useState } from "react";
// import { Routes, Route, Link } from "react-router-dom";
// import { pink } from "@mui/material/colors";
// // import btlahore from "../pages/btlahore.jpg";
// // import Link from '@material-ui/core/Link';

// import {
//   AppBar,
//   Tabs,
//   Tab,
//   Toolbar,
//   Typography,
//   Button,
//   useMediaQuery,
//   IconButton,
//   useTheme,
// } from "@mui/material";
// import CottageIcon from "@mui/icons-material/Cottage";
// import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import LogoutIcon from '@mui/icons-material/Logout';
// import HomeIcon from "@mui/icons-material/Home";
// import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
// // import DrawerComp from "./DrawerComp";
// const PAGES = [];
// const HeaderMUI = () => {
//   const [value, setValue] = useState();
//   const theme = useTheme();
//   console.log(theme);
//   const isMatch = useMediaQuery(theme.breakpoints.down("md"));
//   console.log(isMatch);
//   return (
//     <React.Fragment>
//       <AppBar position="absolute" sx={{ background: "#063970" }}>
//         <Toolbar>
//           {/* <IconButton aria-label="Example" >
//                  <HomeIcon sx={{ color: pink[500] }} />
//                 </IconButton> */}

//           <Button
//             variant="link"
//             color="default"
//             startIcon={<HomeIcon sx={{ color: pink[700] }} />}
//             href="/"
//           >
//            Barhria Town Lahore 
//           </Button>

//           {isMatch ? (
//             <>
//               <Typography sx={{ fontSize: "1.5rem", paddingLeft: "10%" }}>
//                 CSS
//               </Typography>
//               {/* <DrawerComp /> */}
//             </>
//           ) : (
//             <>
//               <Tabs
//                 sx={{ margin: "auto" }}
//                 textColor="inherit"
//                 value={value}
//                 onChange={(e, value) => setValue(value)}
//                 indicatorColor="secondary"
//               >
//                 {PAGES.map((page, index) => (
//                   // <Tab key={index} label={page} component={Link} >
//                   //     </Tab>
//                   <Tab label={page} to={page} component={Link} />
//                 ))}
//                 {/* <Tab label="Products"></Tab>
//                         <Tab label="Products"></Tab>
//                         <Tab label="Products"></Tab>
//                         <Tab label="Products"></Tab>
//                         <Tab label="Products"></Tab> */}
//               </Tabs>
             
             
             
             
//               <LogoutIcon 
//               href="https://billing-reports.vercel.app/"
              
//               /> {" Logout "}
             
             
//             </>
//           )}
//         </Toolbar>
//       </AppBar>

//       <Routes>
//         {PAGES.map((page, index) => (
//           <Route key={index} exact path={`/${page}`}>
//             {/* Render the component for the page here */}
//             {/* For example: <Downloads /> for the "Downloads" page */}
//           </Route>
//         ))}
//       </Routes>
//     </React.Fragment>
//   );
// };

// export default HeaderMUI;
















import React, { useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { pink } from "@mui/material/colors";

import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CottageIcon from "@mui/icons-material/Cottage";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";

const PAGES = [];

const HeaderMUI = () => {
  const [value, setValue] = useState();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const handleLogout = () => {
    // Perform logout logic here (e.g., clear session, remove JWT, etc.)
    navigate("/"); // Redirect to the login page
  };

  return (
    <React.Fragment>
      <AppBar position="absolute" sx={{ background: "#0e103d" }}>
        <Toolbar>
          <Button
            variant="link"
            color="default"
            startIcon={<HomeIcon sx={{ color: "white" }} />}
            href="/"
          >
           Bahria Town Lahore
          </Button>
          {/* <Button
            variant="link"
            color="default"
            // startIcon={<HomeIcon sx={{ color: "white" }} />}
            // href="/"
            style={{marginLeft:'320px',fontSize:'20px'}}
          >
            Billing System Reports
          </Button> */}
          {isMatch ? (
            <>
              <Typography sx={{ fontSize: "1.5rem", paddingLeft: "10%" }}>
                CSS
              </Typography>
            </>
          ) : (
            <>
              <Tabs
                sx={{ margin: "auto" }}
                textColor="inherit"
                value={value}
                onChange={(e, value) => setValue(value)}
                indicatorColor="secondary"
              >
                {PAGES.map((page, index) => (
                  <Tab label={page} to={page} component={Link} />
                ))}
              </Tabs>
              {/* <Button
                variant="link"
                color="inherit"
                startIcon={<LogoutIcon />}
                href="/"
              >
                Logout
              </Button> */}
            </>
          )}
        </Toolbar>
      </AppBar>

      
    </React.Fragment>
  );
};

export default HeaderMUI;
