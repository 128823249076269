import logo from './logo.svg';
import './App.css';
import RouterHomer from './Pages/Electricity/RouterHome'
import { BrowserRouter } from 'react-router-dom';
import Navbarlanding from './Navbarlanding'
function App() {
  return (
    <BrowserRouter>
    <div style={{width:'95%' ,marginLeft:'20px'}}>
    <Navbarlanding/>
      <br/>
      <br/>
      <br/>
      <RouterHomer/>
    </div>
    </BrowserRouter>
  );
}

export default App;
