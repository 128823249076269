import * as React from 'react';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import NavBar from './NavBar';

import { notification } from 'antd';
import Cookies from "js-cookie";


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
 
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        // 'https://billingsystem.bsite.net/api/User/login',
        //'https://localhost:7119/api/User/login',
        'https://billingreports.bsite.net/api/User/login',
        formData
      );
      const jwtToken = formData.user_name;
      console.log(jwtToken);
    
      Cookies.set("jwtToken", jwtToken);
      window.jwtArray = [jwtToken];
      window.alert('Login Successfully');
  
      // Show success notification
      notification.success({
        message: 'Welcome!',
        description: `${jwtToken}!`, // Replace UserName with the actual user name
        style: { display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '40px' },
      });
  
      navigate("/electricdashboard", { replace: true });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          // Show error notification
          notification.error({
            message: 'Error',
            description: 'Invalid username or password',
            style: { display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '40px' },
          });
        } else if (error.response.status === 404) {
          window.alert('Invalid username or password or user type');
        }
      } else {
        console.error('Error occurred during login:', error);
      }
    }
  };
  

  const [formData, setFormData] = useState({
    user_name: '',
    password: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    console.log(formData);
  };

  return (
    <div>
      <NavBar />
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} style={{ marginTop: '60px' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="user_name"
                onChange={handleInputChange}
                autoFocus
                value={formData.user_name}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="password"
                type="password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
