// import { MdBarChart, MdAttachMoney ,MdDone,MdClear,MdDateRange ,MdReceipt  } from "react-icons/md";
// import Widget from "components/widget/Widget";
import { useEffect, useState } from 'react';
import axios from 'axios';
// import { FormControl, Select, MenuItem } from '@mui/material';
import BankReport from "../components/charts/LineChart";
import AmountPaidUnpaid from "../components/charts/AmountPaidUnPaid"
import BillsPaidUnpaid from "../components/charts/BillsPaidUnPaid"
import { FormControl, Select, MenuItem, Grid, Typography, Card, CardContent   } from '@mui/material';
import { MdBarChart, MdAttachMoney, MdDone, MdClear, MdDateRange, MdReceipt } from 'react-icons/md';
import { useContext } from 'react';




function formatNumberWithCommas(number) {
  return new Intl.NumberFormat().format(number);
}

const Dashboard = () => {

  const [chartData, setChartData] = useState([]);
  const [LatestData, setLatestData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('January');
  const [selectedProject, setSelectedProject] = useState('All Projects');
  const [selectedBank, setSelectedBank] = useState('');


  
  useEffect(() => {
    fetchData();
  }, [[selectedMonth, selectedProject]]);

  const fetchData = async () => {
    try {
       const response = await axios.get('https://billingreports.bsite.net/api/Reporting/Electricity'); 
     // const response = await axios.get( 'https://localhost:7119/api/Reporting/Electricity');
      const allMembersData = response.data;

      // Filter out May members
      const mayMembersData = allMembersData.filter(member => member.Month === selectedMonth + "-2024" && member.ProjectName === selectedProject);

      setChartData(mayMembersData);
      // console.log(chartData)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
  };



  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  };


  return (




    //style={{  marginLeft: '680px' }}

    <>
      {/* <Navbar/> */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <h1 style={{ font: 'verdana', color: 'blue', fontSize: '30px', marginRight: '10px' }}>Electricity Analytics Dashboard</h1> */}

        <Card style={{ Width: '800px', margin: '0 auto', marginTop: 20 ,marginLeft:'10px' ,backgroundColor:'#0E2954' }}>
          <CardContent>
            <Typography variant="h5" component="h4" style={{ color: 'white' }}>
            Electricity Dashboard
            </Typography>
          </CardContent>
        </Card>
        <FormControl style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '10px' }}>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          
          <MenuItem value="January-2024">January</MenuItem>
          <MenuItem value="February">February</MenuItem>
          <MenuItem value="March">March</MenuItem>
          <MenuItem value="April">April</MenuItem>
          <MenuItem value="May">May</MenuItem>
          <MenuItem value="June">June</MenuItem>
          <MenuItem value="July">July</MenuItem>
          <MenuItem value="August">August</MenuItem>
          <MenuItem value="September">September</MenuItem>
          <MenuItem value="October">October</MenuItem>
          <MenuItem value="November">November</MenuItem>
          <MenuItem value="December">December</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: '10px' }}>
        <Select
          value={selectedProject}
          onChange={handleProjectChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          <MenuItem value="All Projects">All Projects</MenuItem>
          <MenuItem value="Mohlanwal">Mohlanwal</MenuItem>
          <MenuItem value="Orchards">Orchards</MenuItem>
        </Select>
      </FormControl>
    </div>
      








      <div style={{ marginTop: '40px'  }}>
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#46AECE', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdDateRange style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Billing Month
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ? chartData[0].Month : ''}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#4b49ac', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdBarChart style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Total Bill Amount
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ? formatNumberWithCommas(  chartData[0].BillAmountDue ): ''}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#7978e9', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdDone style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
             Bil Amount Paid
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ? formatNumberWithCommas(  chartData[0].BillAmountpaid) : ''}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: 'red', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdClear style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Bill Amount Unpaid
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ? formatNumberWithCommas( chartData[0].BillAmountUnpaid) : ''}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#5B5BFF', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdReceipt style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Total Bill Generated
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ? formatNumberWithCommas(chartData[0].TotalBills) : ''}</Typography>

            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#ba86ff ', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdDone style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Total  Members 
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ?  formatNumberWithCommas(chartData[0].TotalMembers) : ''}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#25476A', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdDone style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Members paid
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ? formatNumberWithCommas(chartData[0].MembersPaid ): ''}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: 'red', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdClear style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Members Unpaid
              </Typography>
              <Typography variant="subtitle2">
                {chartData.length > 0 ? formatNumberWithCommas(chartData[0].MembersUnpaid.toLocaleString()) : ''}
              </Typography>
            </div>
          </Grid>
        
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#800080', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdBarChart style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Residential Members
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ?  formatNumberWithCommas(chartData[0].ResiMembers ): ''}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#71b88e', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdBarChart style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Commercial Members
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ? formatNumberWithCommas( chartData[0].CommMember ): ''}</Typography>
            </div>
          </Grid>



          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#7978e9', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdBarChart style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Net Metering Members
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ? formatNumberWithCommas( chartData[0].NetMeteringMembers ): ''}</Typography>
            </div>
          </Grid>


          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#7978e9', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdBarChart style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
             Net Metering Bills Generated
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ? formatNumberWithCommas( chartData[0].TotalBillsNM ): ''}</Typography>
            </div>
          </Grid>



          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#7978e9', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdBarChart style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Net Metering Units 
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ? formatNumberWithCommas( chartData[0].TotalUnitsNM ): ''}</Typography>
            </div>
          </Grid>


          {/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <div style={{ backgroundColor: '#46AECE', color: 'white', padding: '10px', borderRadius: '10px' }}>
              <MdBarChart style={{ fontSize: '35px' }} />
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Net Metering UnitS Exp
              </Typography>
              <Typography variant="subtitle2">{chartData.length > 0 ? formatNumberWithCommas( chartData[0].NetMeteringUnitExp ): ''}</Typography>
            </div>
          </Grid> */}
        </Grid>
      </div>



      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1" style={{ marginTop: '-20PX' }}>
        <BankReport
          chartData={chartData}
          selectedMonth={selectedMonth}
          selectedProject={selectedProject}
          selectedBank={selectedBank}
        />


      </div>

 */}



      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1" style={{ marginTop: '-20PX' }}>
        <BillsPaidUnpaid
          chartData={chartData}
          selectedProject={selectedProject}

        />


      </div>






      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1" style={{ marginTop: '-20PX' }}>
        <MemResiComm
          chartData={chartData}
          selectedProject={selectedProject}
          selectedMonth={selectedMonth}
        />
      </div> */}




      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1" style={{ marginTop: '-20PX' }}>
        <AmountPaidUnpaid
          chartData={chartData}
          selectedProject={selectedProject}

        />

      </div>
     

      
    </>
  );
};

export default Dashboard;
