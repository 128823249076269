// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
// import { Form, Dropdown } from 'react-bootstrap';
// import { DataGrid } from '@mui/x-data-grid';
// import { Grid } from '@mui/material';
// import { FormControl, Select, MenuItem } from '@mui/material';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';




// const BarChartComponent = ( {selectedProject,selectedMonth }) => {
//     const [chartData, setChartData] = useState([]);
//     // const [selectedMonth, setSelectedMonth] = useState('');
//     // const [selectedProject, setSelectedProject] = useState('All Projects');

//     useEffect(() => {
//         fetchData();
//     }, []);

//     const fetchData = async () => {
//         try {
//             const response = await axios.get('https://spring-my-all-learnings-production.up.railway.app/reporting');
//             setChartData(response.data);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };


//     const filteredData = chartData.filter(
//         (data) =>
//             data.Month.includes(selectedMonth) && (!selectedProject || data.ProjectName === selectedProject)
//     );

//     const columns = [
//         { field: 'Month', headerName: 'Month', flex: 1 },
//         { field: 'ProjectName', headerName: 'Project Name', flex: 1 },
//         { field: 'TotalMembers', headerName: 'Total Members', flex: 1 },
//         { field: 'ResiMembers', headerName: 'Residential Members', flex: 1 },
//         { field: 'CommMember', headerName: 'Commercial Member', flex: 1 },

//     ];

//     const rows = filteredData.map((dataItem, index) => ({
//         id: index,
//         Month: dataItem.Month,
//         ProjectName: dataItem.ProjectName,
//         TotalMembers: dataItem.TotalMembers,
//         ResiMembers: dataItem.ResiMembers,
//         CommMember: dataItem.CommMember,

//     }));



//     return (

//         <div style={{ marginTop: '50px' }}>

//             {/* Bar Chart */}
//             <Grid container spacing={2}>
//                 <Grid item xs={12} md={12} justifyContent="center" >
//                     <div style={{ height: 350, marginLeft: 10, marginTop: 20, border: '1px solid black' }}>
//                         <h6 style={{ marginBottom: 20, textAlign: 'center' }}>Members Res/Comm</h6>
//                         <ResponsiveContainer width="100%" height={300}>
//                             <BarChart data={filteredData} barSize={80}>
//                                 <CartesianGrid strokeDasharray="3 3" />
//                                 <XAxis dataKey="Month" />
//                                 <YAxis domain={[0, 30000]} />
//                                 <Tooltip />
//                                 <Legend />
//                                 {/* <Bar dataKey="ProjectName" name="Project Name" fill="#ffa600" label="insideRight" ><LabelList dataKey="ProjectName" position="top" formatter={(value) => value.toLocaleString()} /></Bar> */}
//                                 <Bar dataKey="TotalMembers" name="Total Members" fill="#bc5090" label="insideRight" ><LabelList dataKey="TotalMembers" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
//                                 <Bar dataKey="CommMember" name="Comm Members" fill="#ff6361" label="insideRight" ><LabelList dataKey="CommMember" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
//                                 <Bar dataKey="ResiMembers" name="Resi Members" fill="#58508d" label="insideRight" ><LabelList dataKey="ResiMembers" position="top" formatter={(value) => value.toLocaleString()} /></Bar>
//                             </BarChart>
//                         </ResponsiveContainer>
//                     </div>
//                 </Grid>
//             </Grid>
//         </div>
//     );
// };

// export default BarChartComponent;














import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FormControl, Select, MenuItem } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Grid } from '@mui/material';
import { Form, Dropdown } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

const LineChartComponent = ({ selectedProject }) => {
  const [chartData, setChartData] = useState([]);
  const [selectedMonth, setselectedMonth] = useState(['']);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://billingreports.bsite.net/api/Reporting/Electricity');
      setChartData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const filteredData = chartData.filter(
    (data) =>
      data.Month.includes(selectedMonth) && (!selectedProject || data.ProjectName === selectedProject)
  );

  const columns = [
    { field: 'Month', headerName: 'Month', flex: 1 },
    { field: 'TotalMembers', headerName: 'Amount Due ', flex: 1 },
    { field: 'TotalBills', headerName: 'Amount paid', flex: 1 },
    { field: 'MembersPaid', headerName: 'Amount Unpaid', flex: 1 },
  ];

  const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ffc658', '#8884d8', '#82ca9d', '#ffc658', '#ffc658', '#8884d8', '#82ca9d', '#ffc658'];

  const rows = filteredData.map((dataItem, index) => ({
    id: index,
    Month: dataItem.Month,
    ProjectName: dataItem.ProjectName,
    BillAmountDue: parseFloat(dataItem.BillAmountDue).toLocaleString(),
    BillAmountpaid: parseFloat(dataItem.BillAmountpaid).toLocaleString(),
    BillAmountUnpaid:parseFloat(dataItem.BillAmountUnpaid).toLocaleString(),
  }));

  return (
    <div style={{ marginTop: '50px' }}>
      {/* Line Chart */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} justifyContent="center">
          <div style={{ height: 400, marginLeft: 10, marginTop: 20, border: '1px solid black' }}>
            <h6 style={{ marginBottom: 20, textAlign: 'center' }}>Electricity Amount Paid/Unpaid</h6>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={filteredData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Month" />
                <YAxis tickFormatter={(value) => value.toLocaleString('en')} allowDecimals={false} domain={[0, 48900000]} width={120} />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="BillAmountDue"
                  name="Amount Due"
                  stroke="Lime"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="BillAmountpaid"
                  name="Amount paid"
                  stroke="Blue"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="BillAmountUnpaid"
                  name="Amount Unpaid"
                  stroke="red"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LineChartComponent;
