import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FormControl, Select, MenuItem } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent,Typography   } from '@mui/material';
import { Grid } from '@mui/material';
import { Form, Dropdown } from 'react-bootstrap';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

const CircleChartComponent = () => {
  const [chartData, setChartData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('January-2024');
  const [selectedProject, setSelectedProject] = useState('All Projects');
  const [selectedBank, setSelectedBank] = useState('All Banks');

  const COLORS = ['#ab47bc', '#0e103d', '#5e548e', '#e91e63', '#1565c0', '#ef476f'];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://billingreports.bsite.net/api/Reporting/Electricity');
      const modifiedData = response.data.map((item) => {
        let bankNames = [];
        let amounts = [];

        if (item.BankName) {
          bankNames = item.BankName.split('\r\n').filter((name) => name.trim() !== '');
        }

        if (item.Amount) {
          amounts = item.Amount.split('\r\n').filter((amount) => amount.trim() !== '');
        }

        const data = [];

        for (let i = 0; i < bankNames.length; i++) {
          const [bankName, amount] = bankNames[i].split(':').map((part) => part.trim());
          data.push({
            ...item,
            BankName: bankName,
            Amount: parseFloat(amount),
          });
        }

        return data;
      });

      const mergedData = [].concat(...modifiedData);

      setChartData(mergedData);
      // console.log(mergedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setSelectedBank('');
  };

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
  };

  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  };

  const filteredData = chartData.filter(
    (data) =>
      (selectedMonth === 'All Months' || data.Month.includes(selectedMonth)) &&
      (!selectedProject || data.ProjectName === selectedProject) &&
      (!selectedBank || selectedBank === 'All Banks' || data.BankName === selectedBank)
  );

  const uniqueBanks = [...new Set(chartData.map((data) => data.BankName))];

  const columns = [
    { field: 'Month', headerName: 'Month', flex: 1 },
    { field: 'BankName', headerName: 'Bank Name', flex: 1 },
    { field: 'Amount', headerName: 'Amount', flex: 1 },
  ];

  const rows = filteredData.map((dataItem, index) => ({
    id: index,
    Month: dataItem.Month,
    BankName: dataItem.BankName,
    Amount: dataItem.Amount.toLocaleString(),
    ProjectName: dataItem.ProjectName,
  }));

  return (
    <div style={{ marginTop: '0px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <h3 style={{ font: 'verdana', color: 'blue', fontSize: '30px', marginRight: '10px',marginLeft:'100px' }}>Electricity Amount Bank wise </h3> */}
        <Card style={{ Width: 400, margin: '0 auto', marginTop: 20 ,marginLeft:'80px' ,backgroundColor:'#0E2954' }}>
          <CardContent>
            <Typography variant="h6" component="h6" style={{ color: 'white' }}>
             Paid Amount(Bank wise )
            </Typography>
          </CardContent>
        </Card>
        <FormControl style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '10px' }}>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          <MenuItem value="January-2024">January</MenuItem>
          <MenuItem value="February">February</MenuItem>
          <MenuItem value="March">March</MenuItem>
          <MenuItem value="April">April</MenuItem>
          <MenuItem value="May">May</MenuItem>
          <MenuItem value="June">June</MenuItem>
          <MenuItem value="July">July</MenuItem>
          <MenuItem value="August">August</MenuItem>
          <MenuItem value="September">September</MenuItem>
          <MenuItem value="October">October</MenuItem>
          <MenuItem value="November">November</MenuItem>
          <MenuItem value="December">December</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: '10px' }}>
        <Select
          value={selectedProject}
          onChange={handleProjectChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          <MenuItem value="All Projects">All Projects</MenuItem>
          <MenuItem value="Mohlanwal">Mohlanwal</MenuItem>
          <MenuItem value="Orchards">Orchards</MenuItem>
        </Select>
      </FormControl>
      </div>

      {/* Circle Chart */}
      <Grid container spacing={2}>
        <Grid item xs={15} md={16} justifyContent="center" style={{ marginLeft: '20px' }}>
          <div style={{ height: 400, marginLeft: 66, marginTop: 20, border: '1px solid black' }}>
            {/* <h6 style={{ marginBottom: 20, textAlign: 'center' }}>Amount Bank wise</h6> */}
            <ResponsiveContainer width="88%" height={400}>
              <PieChart>
                <Pie
                  data={filteredData}
                  dataKey="Amount"
                  nameKey={'BankName'}
                  cx="50%"
                  cy="50%"
                  innerRadius={50} // Adjust the inner radius value for depth effect
                  outerRadius={120} // Adjust the outer radius value for depth effect
                  fill="#8884d8"
                  label={(entry) => entry[ 'BankName']}
                >
                  {filteredData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Grid>

        <Grid item xs={13} md={16} justifyContent="center" style={{ marginLeft: '90px' }}>
          <div>
            <Table style={{ border: '1px solid black' }}>
              <thead>
                <tr>
                  <th style={{ color: 'black', border: '1px solid black' }} align="center">
                    Month
                  </th>
                  <th style={{ color: 'black' }}>Project Name</th>
                  <th style={{ color: 'black', border: '1px solid black' }} align="center">
                    Bank Name
                  </th>
                  <th style={{ color: 'black', border: '1px solid black' }} align="right">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.id}>
                    <td style={{ color: 'black', border: '1px solid black' }} align="center">
                      {row.Month}
                    </td>
                    <td style={{ color: 'black', border: '1px solid black' }} align="center">
                      {row.ProjectName}
                    </td>
                    <td style={{ color: 'black', border: '1px solid black' }} align="center">
                      {row.BankName}
                    </td>
                    <td style={{ color: 'black', border: '1px solid black' }} align="right">
                      {row.Amount.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CircleChartComponent;


//