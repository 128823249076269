
import * as React from 'react';
import { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import { useNavigate } from "react-router-dom";


const defaultTheme = createTheme();




export default function SignIn() {
  // const { setJWT } = useContext(JWTContext);
  
const handleSubmit = async (event) => {
  event.preventDefault();
  console.log(formData)
  try {
    const response = await axios.post(
      "https://reports.e-billingbahriatownlahore.com/api/User/signup",
      formData
    );
    const jwtToken = response.data.token;
    
    // setJWT(jwtToken);
    // window.jwtArray = [jwtToken];
    window.alert("Register Successfully");
    // navigate("admin/BankReport", { replace: true });
  } catch (error) {
    if (error.response && error.response.status === 404) {
      window.alert("Invalid username or password or user type");
    } else {
      console.error("Error occurred during login:", error);
    }
  }
};



const navigate = useNavigate();

const [formData, setFormData] = useState({
  user_name: "",
  password: "",
});

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData((prevState) => ({
    ...prevState,
    [name]: value,
  }));

  console.log(formData)
};



  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              // id="UserName"
              label="Email Address"
              name="user_name"
              // autoComplete="UserName"
              onChange={handleInputChange}
              autoFocus
              value={formData.user_name}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="password"
              type="password"
              // id="password"
              // autoComplete="current-password"
              value={formData.password}
              onChange={handleInputChange}

            />
       
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
           
          </Box>
        </Box>
        {/* <Copyright sx={{}} /> */}
      </Container>
    </ThemeProvider>
  );
}