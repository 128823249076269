import { useEffect, useState } from 'react';
import axios from 'axios';
import { FormControl, Select, MenuItem, Grid, Typography, Card, CardContent  } from '@mui/material';
import { MdBarChart, MdAttachMoney, MdDone, MdClear, MdDateRange, MdReceipt } from 'react-icons/md';



function formatNumberWithCommas(number) {
    return new Intl.NumberFormat().format(number);
}

const Dashboard = () => {

    const [chartData, setChartData] = useState([]);
    const [LatestData, setLatestData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('January-2024');
    const [selectedProject, setSelectedProject] = useState('All Projects');
    const [selectedBank, setSelectedBank] = useState('');

    useEffect(() => {
        fetchData();
    }, [[selectedMonth, selectedProject]]);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://billingreports.bsite.net/api/Reporting/Maitenance');
            const allMembersData = response.data;

            // Filter out May members
            const mayMembersData = allMembersData.filter(member => member.Month === selectedMonth + "-2023" && member.ProjectName === selectedProject);

            setChartData(mayMembersData);
            // console.log(chartData)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


   
    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
    };



    const handleBankChange = (event) => {
        setSelectedBank(event.target.value);
    };


    return (






        <div style={{ marginTop: '0px' }}>
            {/* <Navbar/> */}


            <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <h1 style={{ font: 'verdana', color: 'blue', fontSize: '30px', marginRight: '10px' }}>Maintenance Analytics Dashboard</h1> */}


        <Card style={{ Width: 400, margin: '0 auto', marginTop: 20 ,marginLeft:'10px' ,backgroundColor:'#0E2954' }}>
          <CardContent>
            <Typography variant="h5" component="h6" style={{ color: 'white' }}>
            Maintenance Dashboard
            </Typography>
          </CardContent>
        </Card>


        <FormControl style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '10px' }}>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          <MenuItem value="January-2024">January</MenuItem>
          <MenuItem value="February">February</MenuItem>
          <MenuItem value="March">March</MenuItem>
          <MenuItem value="April">April</MenuItem>
          <MenuItem value="May">May</MenuItem>
          <MenuItem value="June">June</MenuItem>
          <MenuItem value="July">July</MenuItem>
          <MenuItem value="August">August</MenuItem>
          <MenuItem value="September">September</MenuItem>
          <MenuItem value="October">October</MenuItem>
          <MenuItem value="November">November</MenuItem>
          <MenuItem value="December">December</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: '10px' }}>
        <Select
          value={selectedProject}
          onChange={handleProjectChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          <MenuItem value="All Projects">All Projects</MenuItem>
          {/* <MenuItem value="Mohlanwal">Mohlanwal</MenuItem>
          <MenuItem value="Orchards">Orchards</MenuItem> */}
        </Select>
      </FormControl>
      </div>







            <div style={{ marginTop: '40px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <div style={{ backgroundColor: '#46AECE', color: 'white', padding: '10px', borderRadius: '10px' }}>
                            <MdDateRange style={{ fontSize: '35px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Billing Month
                            </Typography>
                            <Typography variant="subtitle2">{chartData.length > 0 ? chartData[0].Month : ''}</Typography>
                        </div>
                    </Grid>
                   
                    {/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
      <div style={{ backgroundColor: '#5B5BFF', color: 'white', padding: '10px', borderRadius: '10px' }}>
        <MdReceipt style={{ fontSize: '35px' }} />
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Total Units
        </Typography>
        <Typography variant="subtitle2">{chartData.length > 0 ?formatNumberWithCommas( chartData[0].TotalUnits) : ''}</Typography>

      </div>
    </Grid> */}
      
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <div style={{ backgroundColor: '#25476A', color: 'white', padding: '10px', borderRadius: '10px' }}>
                            <MdBarChart style={{ fontSize: '35px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                              Total  Bill Amount 
                            </Typography>
                            <Typography variant="subtitle2">
                                {chartData.length > 0 ? formatNumberWithCommas(chartData[0].BillAmountDue.toLocaleString()) : ''}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <div style={{ backgroundColor: '#189868', color: 'white', padding: '10px', borderRadius: '10px' }}>
                            <MdDone style={{ fontSize: '35px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Bill Amount Paid
                            </Typography>
                            <Typography variant="subtitle2">
                                {chartData.length > 0 ? formatNumberWithCommas(chartData[0].BillAmountpaid.toLocaleString()) : ''}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <div style={{ backgroundColor: 'red', color: 'white', padding: '10px', borderRadius: '10px' }}>
                            <MdClear style={{ fontSize: '35px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Bill Amount Unpaid
                            </Typography>
                            <Typography variant="subtitle2">
                                {chartData.length > 0 ? formatNumberWithCommas(chartData[0].BillAmountUnpaid.toLocaleString()) : ''}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <div style={{ backgroundColor: '#4b49ac', color: 'white', padding: '10px', borderRadius: '10px' }}>
                            <MdBarChart style={{ fontSize: '35px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Project Name
                            </Typography>
                            <Typography variant="subtitle2">{chartData.length > 0 ? chartData[0].ProjectName : ''}</Typography>
                        </div>
                    </Grid>
                
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <div style={{ backgroundColor: '#7F42CA', color: 'white', padding: '10px', borderRadius: '10px' }}>
                            <MdReceipt style={{ fontSize: '35px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Bill Generated
                            </Typography>
                            <Typography variant="subtitle2">{chartData.length > 0 ? chartData[0].TotalBills : ''}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <div style={{ backgroundColor: '#7978e9', color: 'white', padding: '10px', borderRadius: '10px' }}>
                            <MdBarChart style={{ fontSize: '35px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Total Members
                            </Typography>
                            <Typography variant="subtitle2">{chartData.length > 0 ? chartData[0].TotalMembers : ''}</Typography>
                        </div>
                    </Grid>


                                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
      <div style={{ backgroundColor: '#ba86ff ', color: 'white', padding: '10px', borderRadius: '10px' }}>
        <MdDone style={{ fontSize: '35px' }} />
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Members Paid
        </Typography>
        <Typography variant="subtitle2">{chartData.length > 0 ? chartData[0].MembersPaid : ''}</Typography>
      </div>
    </Grid>
    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
      <div style={{ backgroundColor: 'red', color: 'white', padding: '10px', borderRadius: '10px' }}>
        <MdClear style={{ fontSize: '35px' }} />
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Members Unpaid
        </Typography>
        <Typography variant="subtitle2">{chartData.length > 0 ? chartData[0].MembersUnpaid : ''}</Typography>
      </div>
    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <div style={{ backgroundColor: '#800080', color: 'white', padding: '10px', borderRadius: '10px' }}>
                            <MdBarChart style={{ fontSize: '35px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Mohlanwal Members
                            </Typography>
                            <Typography variant="subtitle2">{chartData.length > 0 ? chartData[0].MohlanwalMembers : ''}</Typography>
                        </div>
                    </Grid> */}
                    {/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <div style={{ backgroundColor: '#71b88e', color: 'white', padding: '10px', borderRadius: '10px' }}>
                            <MdBarChart style={{ fontSize: '35px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Orchards Members
                            </Typography>
                            <Typography variant="subtitle2">{chartData.length > 0 ? chartData[0].OrchardsMembers : ''}</Typography>
                        </div>
                    </Grid> */}
                </Grid>
            </div>



        </div>

    );
};

export default Dashboard;
