import { React, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Electricdashboard from "./index";
import BankReport from "./BankReport";
import AmountPaidUnPaid from "./AmountPaidUnPaid";
import ResiComm from "./ResiComm";
import MemPaidUnPaid from "./MemPaidUnPaid";
import Login from "../../Login";
import Signup from '../Signup'

import Maintdashboard from "../Maintenance/MaintDashBoard";
import MaintAmountpaidunpaid from "../Maintenance/AmountPaidUnPaid";
import MaintPaidunpaid from "../Maintenance/MemPaidUnPaid";
import MaintMemBill from "../Maintenance/MaintBill";
import Cookies from "js-cookie";
import Navbarlanding from "../../Navbarlanding";
function RouterHome() {
  const userEmail = Cookies.get("jwtToken");
  const navigate = useNavigate();

  useEffect(() => {
    if (userEmail == "undefined") {
      navigate("/");
    }
  }, [userEmail, navigate]);


   if(userEmail=="umerrana@btl.com") {
    return <Signup/>
  }
 else if (userEmail) {
    return (
      <>
        <Routes>
          <>
            <Route path="/electricdashboard" element={<Electricdashboard />} />
            <Route path="/bankreport" element={<BankReport />} />
            <Route path="/amountpaidunpaid" element={<AmountPaidUnPaid />} />
            <Route path="/mempaidunpaid" element={<MemPaidUnPaid />} />
            <Route path="/resicomm" element={<ResiComm />} />

            {/* Maintenance */}
            <Route path="/maintdashboard" element={<Maintdashboard />} />
            <Route
              path="/maintamountpaidunpaid"
              element={<MaintAmountpaidunpaid />}
            />
            <Route path="/maintpaidunpaid" element={<MaintPaidunpaid />} />
            <Route path="/maintmembill" element={<MaintMemBill />} />
          </>
        </Routes>
      </>
    );
  }
  
  
  else {
    return <Login />;
  }
}

export default RouterHome;
