import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FormControl, Select, MenuItem } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent,Typography  } from '@mui/material';
import { Grid } from '@mui/material';
import { Form, Dropdown } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, LabelList } from 'recharts';






const BarChartComponent = () => {
  const [chartData, setChartData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('January-2024');
  const [selectedProject, setSelectedProject] = useState('All Projects');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://billingreports.bsite.net/api/Reporting/Maitenance');
      setChartData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
  };

  const filteredData = chartData.filter(
    (data) =>
      data.Month.includes(selectedMonth) && (!selectedProject || data.ProjectName === selectedProject)
  );

  const columns = [
    { field: 'Month', headerName: 'Month', flex: 1 },
    { field: 'ProjectName', headerName: 'Project Name', flex: 1 },
    { field: 'TotalMembers', headerName: 'Amount Due ', flex: 1 },
    { field: 'TotalBills', headerName: 'Amount paid', flex: 1 },
    { field: 'MembersPaid', headerName: 'Amount Unpaid', flex: 1 },

  ];

  const rows = filteredData.map((dataItem, index) => ({
    id: index,
    Month: dataItem.Month,
    ProjectName: dataItem.ProjectName,
    BillAmountDue: parseFloat(dataItem.BillAmountDue).toLocaleString(),
    BillAmountpaid: parseFloat(dataItem.BillAmountpaid).toLocaleString(),
    BillAmountUnpaid: parseFloat(dataItem.BillAmountUnpaid).toLocaleString(),

    // MembersUnpaid: dataItem.MembersUnpaid,
  }));

  return (




    <div style={{ marginTop: '0px' }}>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <h3 style={{ font: 'verdana', color: 'blue', fontSize: '30px', marginRight: '10px', marginLeft: '100px' }}>Maintenance Amount Paid/Unpaid </h3> */}
        <Card style={{ Width: 400, margin: '0 auto', marginTop: 20 ,marginLeft:'80px' ,backgroundColor:'#0E2954' }}>
          <CardContent>
            <Typography variant="h6" component="h6" style={{ color: 'white' }}>
             Amount Paid/Unpaid
            </Typography>
          </CardContent>
        </Card>
        <FormControl style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '10px' }}>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          <MenuItem value="January-2024">January</MenuItem>
          <MenuItem value="February">February</MenuItem>
          <MenuItem value="March">March</MenuItem>
          <MenuItem value="April">April</MenuItem>
          <MenuItem value="May">May</MenuItem>
          <MenuItem value="June">June</MenuItem>
          <MenuItem value="July">July</MenuItem>
          <MenuItem value="August">August</MenuItem>
          <MenuItem value="September">September</MenuItem>
          <MenuItem value="October">October</MenuItem>
          <MenuItem value="November">November</MenuItem>
          <MenuItem value="December">December</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: '10px' }}>
        <Select
          value={selectedProject}
          onChange={handleProjectChange}
          style={{ minWidth: '100px' }} // Set a minimum width for the select component
        >
          <MenuItem value="All Projects">All Projects</MenuItem>
          {/* <MenuItem value="Mohlanwal">Mohlanwal</MenuItem>
          <MenuItem value="Orchards">Orchards</MenuItem> */}
        </Select>
      </FormControl>
      </div>



      {/* Bar Chart */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} justifyContent="center" style={{ marginLeft: '20px' }}>
          <div style={{ height: 400, marginLeft: 60, marginTop: 20, border: '1px solid black' }}>
            <h6 style={{ marginBottom: 20, textAlign: 'center' }}>Amount Paid/Unpaid</h6>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={filteredData} barSize={60} >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Month" />
                <YAxis width={120} tickFormatter={(value) => value.toLocaleString('en')}
                  allowDecimals={false} domain={[0, 1234544]} />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="ProjectName" name="Project Name" fill="#bc5090" label="insideRight" ><LabelList dataKey="ProjectName" position="top" formatter={(value) => value.toLocaleString()} /></Bar> */}
                <Bar dataKey="BillAmountDue" name="Amount Due" fill="#ab47bc" label="insideRight" ><LabelList dataKey="BillAmountDue" position="top" formatter={(value) => value.toLocaleString()}  /></Bar>
                <Bar dataKey="BillAmountpaid" name="Amount paid" fill="#0e103d" label="insideRight" ><LabelList dataKey="BillAmountpaid" position="top" formatter={(value) => value.toLocaleString()} barSize={40} /></Bar>
                <Bar dataKey="BillAmountUnpaid" name="Amount Unpaid" fill="red" label="insideRight" ><LabelList dataKey="BillAmountUnpaid" position="top" formatter={(value) => value.toLocaleString()} barSize={40}/></Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>





        <Grid item xs={13} md={16} justifyContent="center" style={{ marginLeft: '90px' }}>
          <div >
            <Table style={{ border: '1px solid black' }} >
              <thead>
                <tr>
                  <th style={{ color: 'black', border: '1px solid black' }} align="center">Month</th>
                  {/* <th style={{ color: 'black' }}>Project Name</th> */}
                  <th style={{ color: 'black', border: '1px solid black' }} align="center">Project Name</th>
                  <th style={{ color: 'black', border: '1px solid black' }} align="right">Amount Due</th>
                  <th style={{ color: 'black', border: '1px solid black' }} align="right">Amount Paid</th>
                  <th style={{ color: 'black', border: '1px solid black' }} align="right">Amount UnPaid</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.id}>
                    <td style={{ color: 'black', border: '1px solid black' }} align="center">{row.Month}</td>
                    {/* <td>{row.ProjectName}</td> */}
                    <td style={{ color: 'black', border: '1px solid black' }} align="center">{row.ProjectName}</td>
                    <td style={{ color: 'black', border: '1px solid black' }} align="right">{row.BillAmountDue}</td>
                    <td style={{ color: 'black', border: '1px solid black' }} align="right">{row.BillAmountpaid}</td>
                    <td style={{ color: 'black', border: '1px solid black' }} align="right">{row.BillAmountUnpaid}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Grid>
      </Grid>


    </div>
  );
};

export default BarChartComponent;








